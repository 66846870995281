<template>
  <div class="container">
    <img alt="Papitos logo" src="./assets/Papitos.png" />
    <Papitos msg="Coming soon!" />
  </div>
</template>

<script>
import Papitos from './components/Papitos.vue'

export default {
  name: 'App',
  components: {
    Papitos
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background-color: #000000;
  position: relative;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

body::before {
  content: "";
  position: absolute;
  top: -8.5%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.15;
  z-index: -1;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  width: 100vw;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 1442px) {
  img {
    max-width: 25%;
    height: auto;
  }
}

@media (min-width: 1027px) and (max-width: 1441px) {
  img {
    max-width: 30%;
    height: auto;
  }
}

@media (min-width: 770px) and (max-width: 1026px) {
  img {
    max-width: 40%;
    height: auto;
  }
}

@media (min-width: 427px) and (max-width: 769px) {
  img {
    max-width: 45%;
    height: auto;
  }
}

@media (min-width: 377px) and (max-width: 426px) {
  img {
    max-width: 80%;
    height: auto;
  }
}

@media (min-width: 322px) and (max-width: 376px) {
  img {
    max-width: 80%;
    height: auto;
  }
}

@media (max-width: 321px) {
  img {
    max-width: 80%;
    height: auto;
  }
}

</style>
