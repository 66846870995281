<template>
  <div class="container">
    <div class="coming_soon">
      <p>Authentic Peruvian Food</p>
      <h1>{{ msg }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/trajana-sans');

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh; /* Centrar verticalmente */
  width: 100vw;  /* Asegurar que ocupa todo el ancho */
  overflow: hidden;
}

.coming_soon {
  color: #FFDF57;
  font-family: 'Trajana Sans', sans-serif;
  text-transform: uppercase;
  text-align: center;
}

h1 {
  animation: blinking 1s infinite alternate;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

/* Responsive adjustments */
@media (min-width: 1027px) and (max-width: 1441px) {
  .coming_soon {
    font-size: 14px;
  }
}

@media (min-width: 770px) and (max-width: 1026px) {
  .coming_soon {
    font-size: 14px;
  }
}

@media (min-width: 427px) and (max-width: 769px) {
  .coming_soon {
    font-size: 12px;
  }
}

@media (min-width: 377px) and (max-width: 426px) {
  .coming_soon {
    font-size: 12px;
  }
}

@media (min-width: 322px) and (max-width: 376px) {
  .coming_soon {
    font-size: 12px;
  }
}

@media (max-width: 321px) {
  .coming_soon {
    font-size: 12px;
  }
}
</style>
